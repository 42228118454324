.hero-content-section {
  @include section-padding;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .section-containing-video;

  background-color: $secondary;
  box-shadow: rem-calc(0 10 0 5) $secondary;
  color: $white;

  @include media-breakpoint-up(lg) {
    box-shadow: rem-calc(0 23 0 10) $secondary;
  }
}

// A generic content section with no specific background
.content-section {
  @include section-padding;
}

.first-content-section {
  @include section-padding;
  @include round-top-corners($white);
}

.second-content-section {
  @include section-padding;

  background-color: $grey;
  box-shadow: rem-calc(0 10 0 5) $grey;

  @include media-breakpoint-up(lg) {
    box-shadow: rem-calc(0 23 0 10) $grey;
  }
}

.filter-content-section {
  @include thin-section-padding;

  background-color: $light-grey;

  &__box-shadow {
    box-shadow: rem-calc(0 10 0 5) $light-grey;

    @include media-breakpoint-up(lg) {
      box-shadow: rem-calc(0 23 0 10) $light-grey;
    }
  }
}

.section-containing-video {
  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
  }
}

.receive-rounded-top-corners-with-white-outline {
  box-shadow: rem-calc(0 10 0 5) $white;

  @include media-breakpoint-up(lg) {
    box-shadow: rem-calc(0 23 0 10) $white;
  }
}
