.icon-button-wrapper {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .nav-link;
  display: flex;
  align-items: center;

  button {
    border-width: 0;
    padding: 0.5rem 0.75rem;
  }

  .icon-button {
    background-color: $primary;
    border-radius: 50%;
    display: inline-block;

    .icon {
      background-color: white;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $white;
      border: 1px solid $gray-600;
      .icon {
        background-color: $gray-600;
      }
    }
  }

  &:hover,
  &:focus {
    .icon-button {
      background-color: $primary-darker;

      &:disabled {
        cursor: not-allowed;
        background-color: $white;
        border: 1px solid $gray-600;
        .icon {
          background-color: $gray-600;
        }
      }
    }
  }

  &:focus-visible {
    .icon-button {
      background-color: $primary-darkest;
    }
  }

  &:active {
    .icon-button {
      box-shadow: $primary-darkest rem-calc(0 0 0 3);

      &:disabled {
        box-shadow: none;
      }
    }
  }
}
