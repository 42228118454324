.button-toggle {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .btn;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .btn-outline-primary;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .rounded-pill;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .w-100;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .border-1;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .px-5;
}

.top-button {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .bg-white;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
  @extend .opacity-100;

  z-index: 10;
  cursor: default;

  &:active,
  &:hover,
  &:focus {
    color: $primary;
  }
}

.bottom-button {
  cursor: pointer;
  background-color: $light-grey;
  transition: all 200ms linear;
  border-color: shade-color($light-grey, 20);

  &:active,
  &:hover,
  &:focus {
    z-index: 20;
    background-color: $primary;
    color: $white;
  }
}

.move-right {
  margin-right: -3rem;
}
