.video-container {
  align-items: center;
  background-size: cover;
  border-radius: rem-calc(10);
  color: $black;
  display: flex;
  font-size: rem-calc(24);
  justify-content: center;
  margin: 1rem 1rem 1.75rem 1rem;
  width: calc(100% - 2rem);

  @include media-breakpoint-up(lg) {
    height: rem-calc(270);
    width: rem-calc(480);
  }

  @include media-breakpoint-up(xl) {
    border-radius: rem-calc(20);
    margin: 1.75rem 1rem;
  }

  @include media-breakpoint-up(xxl) {
    height: rem-calc(374);
    width: rem-calc(658);
    margin: 0;
  }
}

.overlay {
  outline: 6px solid #fff;
  outline-offset: -3px;
  box-shadow: #cfd5d8 0 0 0 4px;
}

.assessment-video {
  border-radius: rem-calc(10);
  padding: 5px;
  background-color: $white;
}
