/* Light Weight */
@font-face {
  font-family: SourceSansPro;
  src: url("../fonts/SourceSansPro-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

/* Regular Weight */
@font-face {
  font-family: SourceSansPro;
  src: url("../fonts/SourceSansPro-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

/* Italic */
@font-face {
  font-family: SourceSansPro;
  src: url("../fonts/SourceSansPro-Italic.ttf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

/* Bold Weight */
@font-face {
  font-family: SourceSansPro;
  src: url("../fonts/SourceSansPro-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}
