.navbar {
  padding: 0 1.5rem;

  @include media-breakpoint-up(md) {
    padding: 3rem 5rem;
  }
  @include media-breakpoint-up(lg) {
    border-bottom: 8px solid $grey;
  }
  .icon-button {
    display: none;

    @include media-breakpoint-up(lg) {
      display: inline-block;
      margin-left: rem-calc(22);
      padding: 1rem;
    }
  }

  .login-icon-button-wrapper {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder -- todo: this should be addressed
    @extend .nav-link;
    display: flex;
    align-items: center;
    margin: 0;

    .text {
      display: none;
    }

    .icon-button {
      display: block;
      padding: 0;
      background-color: $white;
      text-align: center;
      .icon {
        background-color: $primary;
      }
      .icon-label:after {
        content: "Login";

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &:hover,
    &:focus {
      .icon-button {
        background-color: $white;
        .icon {
          background-color: $primary-darker;
        }
      }
    }

    &:focus-visible {
      .icon-button {
        .icon {
          background-color: $primary-darkest;
        }
      }
    }

    &:active {
      .icon-button {
        .icon {
          box-shadow: $primary-darkest rem-calc(0 0 0 3);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      margin: initial;

      .text {
        display: initial;
      }

      .icon-button {
        background-color: $primary;
        border-radius: 50%;
        display: inline-block;
        margin-left: rem-calc(22);
        padding: 1rem;

        .icon {
          background-color: $white;
        }
      }

      &:hover,
      &:focus {
        .icon-button {
          background-color: $primary-darker;
          .icon {
            background-color: $white;
          }
        }
      }

      &:focus-visible {
        .icon-button {
          background-color: $primary-darkest;
        }
      }

      &:active {
        .icon-button {
          box-shadow: $primary-darkest rem-calc(0 0 0 3);
        }
      }
    }
  }
}

.navbar-collapse.collapsing {
  margin-top: 1rem;
}

.navbar-collapse.show {
  margin-top: 1rem;
}

.navbar-toggler.collapsed {
  .icon-toggler {
    mask-image: url("../images/icons/menu.svg");
    -webkit-mask-image: url("../images/icons/menu.svg");
  }
  .icon-label:after {
    content: "Menu";
  }
}

.navbar-toggler {
  padding: 0;

  .icon-toggler {
    mask-image: url("../images/icons/close.svg");
    -webkit-mask-image: url("../images/icons/close.svg");
  }
  .icon-label:after {
    content: "Close";
  }
  &:focus-visible {
    .icon-toggler {
      background-color: $primary-darkest;
    }
    .icon-label:after {
      color: $primary-darkest;
    }
  }
  &:focus {
    box-shadow: none;
  }
}

.nav-link {
  &:hover,
  &:focus {
    color: $grey;
  }

  @include media-breakpoint-up(lg) {
    color: $primary;

    &:hover,
    &:focus {
      color: $primary-darker;
    }

    &:active {
      color: $primary-darkest;
    }
    &:focus-visible {
      outline: none;
      color: $black;
    }
  }
}

.nav-item {
  background-color: $secondary;
  padding: 0.5rem 0.75rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    background-color: $white;
    // ensures dropdown sits evenly beneath user controls
    &:last-of-type {
      margin-left: auto;
    }
  }
}

.navbar-brand {
  margin: 0.5rem;
  img {
    height: rem-calc(48);
    @include media-breakpoint-up(md) {
      height: rem-calc(105);
    }
  }
  img.collapsed {
    @include media-breakpoint-up(md) {
      height: rem-calc(69);
    }
  }
}

.mobile-dropdown-arrow {
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
