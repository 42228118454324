.btn-link {
  font-weight: bold;
  text-decoration: none;
  padding: 0;
  text-align: left;

  &:hover {
    color: $primary-darker;
  }

  &:focus {
    color: $primary-darker;
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: $primary-darkest rem-calc(0 0 0 3);
  }

  &:active {
    color: $primary-darkest;
  }

  &.white {
    color: $white;
    padding: 0 2rem 1rem 0;

    &:hover {
      color: $gray-400;
    }

    &:focus {
      box-shadow: none;
      color: $gray-400;
    }

    &:focus-visible {
      outline: none;
      box-shadow: $gray-600 rem-calc(0 0 0 3);
    }
  }
}
