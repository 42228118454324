.footer {
  @include section-padding;
  @include round-top-corners($secondary);

  background-color: $secondary;
  color: $white;

  .footer-logo {
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 3.25rem;
    }
  }

  .footer-text-wrapper {
    margin: 1.5rem 0 2.75rem 0;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 3.5rem 0 1.5rem 0;
    }
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }
}
