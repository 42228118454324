[data-bs-toggle="collapse"] {
  &[aria-expanded="true"] {
    .collapse-text--expanded {
      display: inherit;
    }

    .collapse-text--collapsed {
      display: none;
    }
  }

  .collapse-text--collapsed {
    display: inherit;
  }

  .collapse-text--expanded {
    display: none;
  }
}
