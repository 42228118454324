@mixin section-padding {
  justify-content: space-between;
  padding: 2rem 1rem 3rem 1rem;

  @include media-breakpoint-up(md) {
    padding: 3rem;
  }

  @include media-breakpoint-up(xxl) {
    justify-content: space-between;
    padding: 4rem 5.625rem;
  }
}

@mixin thin-section-padding {
  justify-content: space-between;
  padding: 1.5rem 1rem 3rem 1rem;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 3rem 3rem 3rem;
  }

  @include media-breakpoint-up(xxl) {
    justify-content: space-between;
    padding: 2rem 5.625rem 4rem 5.625rem;
  }
}

@mixin round-top-corners($border-top-color) {
  border-radius: rem-calc(20 20 0 0);
  border-top: 25px solid $border-top-color;

  @include media-breakpoint-up(lg) {
    border-radius: rem-calc(40 40 0 0);
    border-top: 45px solid $border-top-color;
  }
}
