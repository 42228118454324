/* Define the icons we wish to support.
 * Each of these icons requires a corresponding SVG file in
 * app/frontend/images/icons, and will get a CSS class .icon-{{name}}.
*/
$icons: video, video-outline, download, user, user-circle, carot-left, award,
  carot-right, clipboard, pen, user-group, plus, calendar, rate, tick;

.icon {
  background-color: $dark;
  content: "";
  display: inline-block;
  height: 1rem;
  mask-repeat: no-repeat;
  mask-size: 1rem 1rem;
  vertical-align: -0.125em;
  width: 1rem;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 1rem 1rem;

  &.size-2 {
    height: 2rem;
    mask-size: 2rem 2rem;
    width: 2rem;
    -webkit-mask-size: 2rem 2rem;
  }
}

@each $icon in $icons {
  .icon-#{$icon} {
    mask-image: url("../images/icons/#{$icon}.svg");
    -webkit-mask-image: url("../images/icons/#{$icon}.svg");
  }
}

.icon-label {
  display: flex;
  flex-direction: column;

  &:after {
    font-weight: bold;
    color: $primary;
  }
}
