.appointment-rating-field {
  height: 40px;
  border-color: $primary;

  &--file {
    height: 40px;
    border-color: $primary;
    background-color: $white;
    padding: 0.5rem;

    &:hover {
      background-color: $white;

      &:not(:disabled):not([readonly])::file-selector-button {
        background-color: $primary-darker;
      }
    }

    &::file-selector-button {
      font-weight: bold;
      color: $white;
      background-color: $primary;
      border-width: 0;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 0.875rem;
    }
  }
}
