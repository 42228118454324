h1 {
  &.plain {
    &:after {
      display: none;
    }
  }
  &:after {
    background-color: $brand-ochre;
    border-radius: rem-calc(10);
    content: "";
    display: block;
    height: rem-calc(7);
    margin: 1rem 0;
    width: rem-calc(100);
  }
}

h2:after {
  width: rem-calc(10);
  height: rem-calc(10);
  content: "";
  display: block;
  border-radius: 50%;
  box-shadow:
    rem-calc(0 10 0 0) $brand-ochre,
    rem-calc(14 10 0 0) $brand-orange,
    rem-calc(28 10 0 0) $brand-blue,
    rem-calc(42 10 0 0) $primary;
  margin: 0 0 2rem 0;
}

h2.aligned-center:after {
  margin: 0 auto 2rem auto;
  box-shadow:
    rem-calc(-21 10 0 0) $brand-ochre,
    rem-calc(-7 10 0 0) $brand-blue,
    rem-calc(7 10 0 0) $brand-orange,
    rem-calc(21 10 0 0) $primary;
}
