// Override RBC defaults and import
$time-selection-bg-color: $primary;
$event-border: "none";
$today-highlight-bg: "white";
$event-bg: $primary;
$event-border-radius: 0;
@import "react-big-calendar/lib/sass/styles";

.rbc-time-content {
  border-top: none;
}

.weekend-day {
  background-color: rgba($grey, 0.2);
}

.rbc-time-gutter {
  text-align: center;

  &.rbc-time-column {
    .rbc-timeslot-group {
      font-size: rem-calc(16);
      &:first-child {
        margin-top: -0.35rem;
        max-height: 55px;
      }
      margin-top: -0.5rem;
      border-bottom: none;
      color: $gray-700;
    }
  }
}

.rbc-day-slot {
  text-align: right;

  &.rbc-time-column {
    .rbc-timeslot-group {
      .rbc-time-slot:last-child {
        background-image: linear-gradient(
          to right,
          $gray-500 50%,
          transparent 50%
        );
        background-position: left top;
        background-repeat: repeat-x;
        background-size: 0.5rem 1%;
      }
    }
  }
}
.rbc-header {
  font-weight: normal;

  h3 {
    font-weight: bold;
  }
}

.rbc-time-view {
  border-left: none;
  border-top: none;
  border-bottom: none;
}
.rbc-time-header-content {
  border-bottom: 1px solid $gray-300;

  .rbc-time-header-cell {
    min-height: rem-calc(80);
  }
}

// Disable the default all day slot
.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}

.all-day-cell {
  padding: 0.45rem 0;
  border-top: 1px solid $gray-300;
}
