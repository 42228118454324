.dropdown-item {
  font-weight: bold;
  color: $primary;

  &:hover,
  &:focus {
    color: $primary-darker;
  }

  &:active {
    color: $primary-darkest;
  }
  &:focus-visible {
    outline: none;
    color: $black;
  }
}
