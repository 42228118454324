.day-wrapper {
  flex-grow: 1;
  text-align: center;
  border: solid 2px transparent;
  border-bottom: none;

  &:focus-visible {
    outline: none;
    border-radius: $border-radius $border-radius 0 0;
    border-color: $primary-darkest;

    .day-title {
      color: $primary-darkest;

      &:after {
        border-top: 4px solid $primary-light;
      }
    }
  }
}

.day-title {
  color: $primary;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  font-weight: 300;

  &:after {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    content: "";
  }

  &:hover {
    color: $primary-darkest;

    &:after {
      border-top: 4px solid $primary-light;
    }
  }

  &.active {
    color: $primary-darker;
    font-weight: 700;

    &:after {
      border-top: 4px solid $primary-darker;
    }
  }
}
