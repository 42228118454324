// Override Bootstrap's Sass default variables
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// To see a full list of variables that can be overwritten visit https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// needs to be above all other imports
@use "sass:map";
@import "bootstrap/scss/functions"; // required
@import "bootstrap/scss/mixins"; // required

// Customize some defaults
$primary: #628030;
$primary-darker: #40600c;
$primary-darkest: #2c4502;
$primary-light: #c0ccac;
$secondary: #033646;
$success: #038500;
$warning: #ffae00;
$warning-light: #ffe7b4;
$alert: #cc4b37;
$black: rgba(#000, 0.8);
$white: #fff;
$grey: #cfd5d8;
$light-grey: #f5f7f7;
$brand-ochre: #c68b10;
$brand-blue: #008ea1;
$brand-orange: #cd581b;

// Add colours to this list to generate colour variant classes
@import "bootstrap/scss/variables"; // required

// stylelint-disable-next-line scss/no-global-function-names -- todo: this should be addressed
$theme-colors: map-merge(
  (
    "grey": $grey,
    "light-grey": $light-grey,
    "primary-light": $primary-light,
    "primary-darker": $primary-darker,
    "primary-darkest": $primary-darkest
  ),
  $theme-colors
);

// Bootstrap variables are already defined once variables are loaded,
// so we need to re-initialise them here to include the theme colours
// we have defined.
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-text: map.merge(
  $theme-colors-rgb,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-color)
  )
);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text");

$utilities-bg: map.merge(
  $theme-colors-rgb,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-bg)
  )
);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");

// scss-docs-start border-radius-variables
$border-radius: 10px;
$border-radius-sm: 6px;
$border-radius-lg: 1rem;

// Headings

// Typography
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
$font-family-sans-serif:
  SourceSansPro,
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  "Liberation Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

$font-size-base: 1.125rem;
$font-size-sm: 1rem;
$font-size-lg: 1.5rem;

$line-height-base: 1.4;

$headings-font-weight: 700;

$h1-font-size: 2.875rem; // 46px
$h2-font-size: 2.375rem; // 38px
$h3-font-size: 2rem; // 32px
$h4-font-size: 1.75rem; // 28px
$h5-font-size: 1.5rem; // 24px
$h6-font-size: 1.25rem; // 20px

// scss-docs-end font-variables

$hr-opacity: 1;

// Buttons
$btn-line-height: 25px;
$btn-border-radius: $border-radius-sm;
$btn-padding-y: 17px;
$btn-hover-bg-shade-amount: 30%;
$btn-hover-bg-tint-amount: 30%;
$btn-active-bg-shade-amount: 55%;
$btn-active-bg-tint-amount: 55%;
$btn-font-weight: bold;
$btn-font-size: 1.1rem; // ~ 18px

// Forms

// scss-docs-start form-text-variables

// scss-docs-start form-label-variables
$form-label-color: #000;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: 0.75rem;
$input-bg: $grey;
$input-border-color: $secondary;
$input-box-shadow: none;

$input-focus-bg: tint-color($grey, 80%);
$input-focus-border-color: $secondary;
$input-focus-box-shadow: none;

// scss-docs-start form-check-variables
$form-check-input-width: 1.5em;
$form-check-input-border: 1px solid $primary;
$form-check-input-focus-border: $secondary;
$form-check-input-focus-box-shadow: none;
$form-check-input-border-radius: 0;
$form-check-input-bg: none;
// scss-docs-end form-check-variables

// Navs

// scss-docs-start nav-variables
$nav-link-font-weight: 700;
$nav-link-color: $white;
// scss-docs-end nav-variables

// Dropdowns
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$enable-caret: false;
$dropdown-border-radius: $border-radius;
$dropdown-min-width: 13rem; // ~ 200px
$dropdown-spacer: 1rem;
$dropdown-border-color: $grey;
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-font-size: 1.125rem;

$dropdown-link-color: $primary;

// Cards

// scss-docs-start card-variables
$card-border-radius: $border-radius;
// scss-docs-end card-variables

// Accordion
$accordion-color: $black;
$accordion-padding-y: 0.25rem;
$accordion-padding-x: 1rem;
$accordion-bg: $white;
$accordion-border-width: 1px;
$accordion-border-color: $grey;
$accordion-border-radius: 1rem;

$accordion-body-padding-y: 2rem;
$accordion-body-padding-x: 2rem;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $black;
$accordion-button-bg: $white;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $black;

$accordion-icon-width: 2rem;
$accordion-icon-color: $white;
$accordion-icon-active-color: $white;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(180deg);

$accordion-button-icon: url("../images/icons/angle-down");
$accordion-button-active-icon: url("../images/icons/angle-down");

// Lead text
$lead-font-weight: 400;

// Tables
$table-th-font-weight: 300;
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 1rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;
$table-group-separator-color: $white;

// Bring in Bootstrap
// Configuration
@import "./mixins/buttons"; // needs to be after bootstrap mixins to customise them

@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root"; // required
@import "bootstrap/scss/reboot"; // required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom styles

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);
// stylelint-disable-next-line scss/no-global-function-names -- todo: this should be addressed
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

.btn-outline-primary {
  border-width: 3px;
}

.rounded-top-lg {
  border-top-left-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;
}

.fs-normal {
  font-size: $font-size-base;
}

input {
  &:hover,
  &:active {
    background-color: $gray-200;
  }

  &:focus-visible {
    @include border-radius;

    outline: none;
    box-shadow: $primary-darkest rem-calc(0 0 0 3);
  }
}

.form-check-input:focus-visible {
  box-shadow: $primary-darkest rem-calc(0 0 0 3);
}

.table {
  > :not(:last-child) > :last-child > * {
    border-bottom-width: 0.35rem;
  }
}
