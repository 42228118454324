/**
 * Wraps form checkboxes so that labels and inputs appear like this:
 * [ ] This label is
 *     wrapped
 *
 * Instead of:
 * [ ] This label is
*  wrapped
*/

$form-check-wrap-size: $form-check-input-width * 2;

.form-check-label {
  padding-left: $form-check-wrap-size;

  .form-check-input {
    margin-right: 0.75rem;
    margin-left: -$form-check-wrap-size;
  }
}
