.accordion-item {
  margin-top: 1.5rem;
  border-radius: 10px;

  // this should not be necessary, however bootstrap is refusing to behave
  &:first-of-type {
    .accordion-header {
      @include border-top-radius(10px);
      @include border-bottom-radius(10px);
      .accordion-button {
        @include border-top-radius(10px);
        @include border-bottom-radius(10px);
      }
    }
  }

  .accordion-header {
    @include border-top-radius(10px);
    @include border-bottom-radius(10px);
    .accordion-button {
      @include border-top-radius(10px);
      @include border-bottom-radius(10px);
    }
  }

  &:last-of-type {
    .accordion-header {
      @include border-top-radius(10px);
      @include border-bottom-radius(10px);
      .accordion-button {
        @include border-top-radius(10px);
        @include border-bottom-radius(10px);
      }
    }
  }
}

.accordion-button {
  //This should not be needed, but some bootstrap defaults are interfaring with the box-shadow by itself
  &:not(.collapsed) {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  }
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border: solid 1px $grey;
  font-size: 1.5rem;
  padding: 0.5rem 0.75rem;

  @include media-breakpoint-up(md) {
    padding: 0.5rem 2rem;
  }

  &:hover,
  &:focus {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

    &:after {
      color: $primary-darker;
    }
  }

  &:active {
    &:after {
      color: $primary-darker;
    }
  }

  &:focus-visible {
    box-shadow: $primary-darkest rem-calc(0 0 0 3);
    outline: none;
  }

  &:after {
    background-color: $primary;
    border-radius: 50%;
  }
}

.accordion-status {
  background-color: $primary-light;

  &--action-required {
    background-color: $warning-light;
  }
}
